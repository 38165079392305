// userByMobileSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserByMobile } from "../Api";

export const fetchUserByMobile = createAsyncThunk(
  "userByMobile/fetchUserByMobile",
  async (mobileno, { rejectWithValue }) => {
    try {
      const response = await getUserByMobile(mobileno);
      console.log(response, "lllo");
      return response;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch user by mobile");
    }
  }
);

const userByMobileSlice = createSlice({
  name: "userByMobile",
  initialState: {
    userData: null,
    isLoading: false,
    error:null,
    isSuccess: false,
  },
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserByMobile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserByMobile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData = action.payload.data;
        state.isSuccess = action.payload.isSuccess;
        state.error = action.payload.message;
      })
      .addCase(fetchUserByMobile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

export const selectUserData = (state) => state.userByMobile.userData;
export const selectUserLoading = (state) => state.userByMobile.isLoading;
export const selectUserError = (state) => state.userByMobile.error;
export const selectIsSuccess = (state) => state.userByMobile.isSuccess;

export default userByMobileSlice.reducer;
