import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AddVisitbyAdmin, updateExpense } from "../Api";

export const AddNewVisit = createAsyncThunk(
  "Addvisit/Addvisitbyadmin",
  async (visitdata, { dispatch }) => {
    const response = await AddVisitbyAdmin(visitdata);
    console.log("updatedExpense", response);
    // Dispatch fetchExpenseDetails only if the update was successful

    return response;
  }
);

const AddvisitSlice = createSlice({
  name: "AddVisitnew",
  initialState: {
    Addvisitdata: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddNewVisit.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccess = false;
      })
      .addCase(AddNewVisit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.Addvisitdata = action.payload.message;
        state.isSuccess = action.payload.isSuccess;
      })
      .addCase(AddNewVisit.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.isSuccess = false;
      });
  },
});

export const selectAddVisitData = (state) =>
  state.addVisitByAdmin.Addvisitdata ;
export const selectAddVisitSuccess = (state) =>
  state.addVisitByAdmin.isSuccess ;
export const selectAddVisitLoading = (state) =>
  state.addVisitByAdmin.isLoading ;
export default AddvisitSlice.reducer;
