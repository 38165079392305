import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateExpense } from "../Api";
import { fetchExpenseDetails } from "./getAllExpenses";


export const updateExpenseById = createAsyncThunk(
  "updateExpense/updateExpenseById",
  async (updatedExpense, { dispatch }) => {
    const response = await updateExpense(updatedExpense);
    console.log("updatedExpense", response.isSuccess);
    // Dispatch fetchExpenseDetails only if the update was successful
    if (response.isSuccess) {
      dispatch(
        fetchExpenseDetails({
          fromDate: updatedExpense.formattedFromDate,
          toDate: updatedExpense.formattedToDate,
        })
      );
    }

    return response;
  }
);

const updateExpenseSlice = createSlice({
  name: "updateExpense",
  initialState: {
    updatedExpense: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateExpenseById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccess = false;
      })
      .addCase(updateExpenseById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updatedExpense = action.payload.message;
      })
      .addCase(updateExpenseById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.isSuccess = false;
      });
  },
});

export const selectUpdatedExpense = (state) =>
  state.updateExpense.updatedExpense;

export const selectIsUpdateSuccess = (state) => state.updateExpense.isSuccess;
export default updateExpenseSlice.reducer;
