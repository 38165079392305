import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BeatLoader } from "react-spinners";
import {
  fetchMasterRule,
  createNewRule,
} from "../app/features/masterRuleSlice";
function MasterPage() {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.masterRule);
  const [rules, setRules] = useState([]);
  const [lang, setLang] = useState("");
  const [lat, setLat] = useState( "");

  const [content, setContent] = useState( "");
  // const [selectedFile, setSelectedFile] = useState(null);
  useEffect(() => {
    // Fetch master rule data when the component mounts
    dispatch(fetchMasterRule());
    console.log("64", data[0]);
  }, [dispatch]);
  useEffect(() => {
    // Set initial values when data is available
    if (data && data.length > 0) {
      setLang(data[0].lang || "");
      setLat(data[0].lat || "");
      setContent(data[0].rulesParagraph || "");
    }
  }, [data]);

  // const addRule = () => {
  //   if (points && quantity) {
  //     setRules([...rules, { points, quantity }]);
  //     setPoints("");
  //     setQuantity("");
  //   }
  // };
  const handleChange = (htmlContent) => {
    setContent(htmlContent);
  };
  // const handleFileChange = (e) => {
  //   // Update the state with the selected file
  //   setSelectedFile(e.target.files[0]);
  // };

  const handleSave = () => {
    // Create a new rule object with the updated data
    const ruleData = {
      ID: data[0].id,
      lang: lang,
      lat: lat,
      RulesParagraph: content,
      Point: "0",
      UpdatedDate: new Date(),
    };
    console.log(ruleData);
    // Dispatch the createNewRule action to create/update the rule
    dispatch(createNewRule(ruleData))
      .unwrap()
      .then(() => {
        dispatch(fetchMasterRule());
        // Handle success, maybe show a success message or navigate to another page
        console.log("Rule created/updated successfully!");
      })
      .catch((error) => {
        // Handle error, show an error message to the user
        console.error("Failed to create/update rule:", error);
      });
  };

  return (
    <div className="flex justify-center items-center  overflow-y-auto">
      {loading ? (
      
        <div className="flex flex-col items-center justify-center h-screen">
          <h1 className="text-center text-2xl font-bold mb-4">
            your data is loading, please wait...
          </h1>
          <div className="mb-8">
            <BeatLoader size={50} color={"black"} loading={true} />
          </div>
        </div>
      
      ) : (
        <div className="w-96 p-4">
          <h1 className="text-2xl font-semibold mb-4 bg-slate-300 rounded-md p-4">
            Rules Configuration
          </h1>
          {/* <div className="mb-4">
            <label htmlFor="points" className="block font-semibold">
              Points:
            </label>
            <input
              type="number"
              id="points"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
              className="border border-gray-300 p-2 rounded-lg w-full"
            />
          </div> */}
          <div className="mb-4">
            <label htmlFor="lang" className="block font-semibold">
              Langitude:
            </label>
            <input
              type="number"
              id="lang"
              value={lang}
              onChange={(e) => setLang(e.target.value)}
              className="border border-gray-300 p-2 rounded-lg w-full"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lat" className="block font-semibold">
              Latiitude:
            </label>
            <input
              type="number"
              id="lat"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
              className="border border-gray-300 p-2 rounded-lg w-full"
            />
          </div>
          {/* <div className="mb-4">
            <label htmlFor="file" className="block font-semibold">
              Upload Image:
            </label>
            <input
              type="file"
              id="file"
              onChange={handleFileChange}
              className="border border-gray-300 p-2 rounded-lg w-full"
            />
          </div> */}
          {/* {selectedFile && (
            <div className="mb-4">
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Selected Image"
                className="max-w-full h-auto"
              />
            </div>
          )} */}
          <div className="mb-4">
            <ReactQuill
              value={content}
              onChange={setContent}
              className=" h-32"
            />
          </div>
          <button
            onClick={handleSave}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-16"
          >
            Save Rules
          </button>
        </div>
      )}
    </div>
  );
}

export default MasterPage;
