
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateGiftRequestStatus } from "../Api";

export const updateGiftRequest = createAsyncThunk(
  "giftRequests/updateGiftRequestStatus",
  async ({ orderId, newStatus }, { rejectWithValue }) => {
    try {
      const response = await updateGiftRequestStatus(orderId, newStatus);
      console.log(response)
      return response.data; // Assuming the API response contains updated gift request data

    } catch (error) {
      return rejectWithValue(error.message || "Failed to update gift request status");
    }
  }
);

const gifStatusSlice = createSlice({
  name: "giftRequests",
  initialState: {
    updatedGiftRequestData: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateGiftRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGiftRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updatedGiftRequestData = action.payload;
      })
      .addCase(updateGiftRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default gifStatusSlice.reducer;
