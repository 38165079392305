import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import AddVisitModal from "../components/AddVisitModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserByMobile,
  selectUserData,
  selectUserLoading,
  selectUserError,
} from "../app/features/addvisitSlice";
import {
  AddNewVisit,
  selectAddVisitSuccess,
  selectAddVisitLoading,
} from "../app/features/AddvisitByAdmin";
import {
  AddBonusByAdmin,
  selectAddBonusSuccess,
  selectAddBonusLoading,
} from "../app/features/addBonusSlice";
import AddBonus from "../components/AddBonus";

function formatDate(date) {
  // Implement your date formatting logic here
  return date;
}

function AddVisitPage() {
  const columns = [
    //   { id: "srno", label: "Sr No.", minWidth: 100 },
    { id: "name", label: "Name", minWidth: 100 },
    {
      id: "mobile",
      label: "Mobile Number",
      minWidth: 250,
      align: "center",
    },
    {
      id: "vehicleno",
      label: "Vehicle No.",
      minWidth: 100,
      align: "right",
    },
    {
      id: "vehicleType",
      label: "Vehicle Type.",
      minWidth: 100,
      align: "right",
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 100,
      align: "center",
      format: (rowData) => {
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAddVisitModal}
            >
              Add Visit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOpenAddBonusModal}
              style={{ marginLeft: 8 }}
            >
              Add Bonus
            </Button>
          </>
        );
      },
    },
  ];
  const [openModal, setOpenModal] = useState(false);
  const [searchMobile, setSearchMobile] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const isLoading = useSelector(selectUserLoading);
  const error = useSelector(selectUserError);
  const addVisitLoading = useSelector(selectAddVisitLoading);
  const addVisitSuccess = useSelector(selectAddVisitSuccess);
  console.log(addVisitLoading, addVisitSuccess);
  console.log(error);
  const [openAddVisitModal, setOpenAddVisitModal] = useState(false);
  const [openAddBonusModal, setOpenAddBonusModal] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const handleOpenAddVisitModal = () => {
    setOpenAddVisitModal(true);
  };

  const handleCloseAddVisitModal = () => {
    setOpenAddVisitModal(false);
  };
  const handleOpenAddBonusModal = () => {
    setOpenAddBonusModal(true);
  };

  const handleCloseAddBonusModal = () => {
    setOpenAddBonusModal(false);
  };

 
  const handleAddBonus = (bonusData) => {
    dispatch(AddBonusByAdmin(bonusData))
      .then(() => {
        handleCloseAddBonusModal();
        setSuccessMessage("Bonus added successfully!");
        toast.success("Bonus added successfully!");
      })
      .catch((error) => {
        console.error("Error adding bonus:", error);
      });
  };

  const handleAddVisit = (visitData) => {
    // Add logic to handle adding a visit
    dispatch(AddNewVisit(visitData))
      .then(() => {
        // Dispatch an action, make an API call, or update state as needed
        handleCloseAddVisitModal(); // Close the modal after handling the visit
        setSuccessMessage("Visit added successfully!");
        toast.success("Visit added successfully!");
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error adding visit:", error);
      });
  };
  const handleSearch = () => {
    // Check if the entered mobile number is valid (10 digits)
    if (searchMobile.length === 10) {
      // Dispatch the fetchUserByMobile action
      dispatch(fetchUserByMobile(searchMobile));
    } else {
      // Alert the user if the mobile number is not 10 digits
      alert("Please enter a 10-digit mobile number.");
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <div className="p-4 bg-slate-200">
        <div className="flex items-center justify-between mb-4 ">
          <div className="flex items-center">
            <TextField
              label="Search"
              variant="outlined"
              value={searchMobile}
              onChange={(e) => setSearchMobile(e.target.value)}
              style={{ backgroundColor: "white" }}
            />

            <button
              onClick={handleSearch}
              className="bg-blue-500 text-white px-4 py-2 mx-2 rounded-md hover:bg-blue-600 transition duration-300"
            >
              Search
            </button>
            {/* {isLoading && <CircularProgress size={24} />} */}
          </div>
          {/* <button
            onClick={handleOpenModal}
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300"
          >
            Add New User
          </button> */}
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-40">
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className="font-bold"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userData?.map((row) => (
                <TableRow key={row.srno}>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === "actions" ? (
                          addVisitLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenAddVisitModal}
                              >
                                Add Visit
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleOpenAddBonusModal}
                                style={{ marginLeft: 8 }}
                              >
                                Add Bonus
                              </Button>
                            </>
                          )
                        ) : column.format ? (
                          column.format(row[column.id])
                        ) : (
                          row[column.id]
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!isLoading && !error && (!userData || userData.length === 0) && (
        <p className="text-green-700 text-center font-bold">
          Search User With Register Mobile To Add Visit or Add User
        </p>
      )}
      {!isLoading && error !== null && (
        <p className="text-red-500 text-center font-bold">
          {error}. Please Add User first.
        </p>
      )}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle className="text-lg font-bold">Add New User</DialogTitle>
        <DialogContent>
          {/* Add form fields for name, mobile, vehicle type, role, image, and referral code */}
          <TextField label="Name" fullWidth className="mb-4" />
          <TextField label="Mobile" fullWidth className="mb-4" />
          <TextField label="Vehicle Type" fullWidth className="mb-4" />
          {/* Add other form fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} className="text-gray-700">
            Cancel
          </Button>
          <Button onClick={handleCloseModal} color="primary">
            Add User
          </Button>
        </DialogActions>
      </Dialog>
      <AddVisitModal
        isOpen={openAddVisitModal}
        handleClose={handleCloseAddVisitModal}
        handleAddVisit={handleAddVisit}
        userData={userData}
      />
      <AddBonus
        isOpen={openAddBonusModal}
        handleClose={handleCloseAddBonusModal}
        handleAddBonus={handleAddBonus}
        userData={userData}
      />
      <ToastContainer />
    </div>
  );
}

export default AddVisitPage;
