import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AddBonus, updateExpense } from "../Api";

export const AddBonusByAdmin = createAsyncThunk(
  "bonusdata/Addbonusdatabyadmin",
  async (bonusdata, { dispatch }) => {
    const response = await AddBonus(bonusdata);
    console.log("updatedExpense", response);
    // Dispatch fetchExpenseDetails only if the update was successful

    return response;
  }
);

const AddBonusSlice = createSlice({
  name: "bonusdata",
  initialState: {
    AddBonusdata: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddBonusByAdmin.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccess = false;
      })
      .addCase(AddBonusByAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.AddBonusdata = action.payload.message;
        state.isSuccess = action.payload.isSuccess;
      })
      .addCase(AddBonusByAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.isSuccess = false;
      });
  },
});

export const selectAddBonusdata = (state) => state.addBonusByAdmin.AddBonusdata;
export const selectAddBonusSuccess = (state) => state.addBonusByAdmin.isSuccess;
export const selectAddBonusLoading = (state) => state.addBonusByAdmin.isLoading;
export default AddBonusSlice.reducer;
